import React from "react";

export default function Accordion() {
  return (
    <section className="ui-accordion">
      <h1>Accordion</h1>
      <h2>Use</h2>
      <p>
        A type of collapse with a list of headers stacked vertically. When
        clicked, the headers reveal content in a panel below
      </p>
      <h2>Examples</h2>
      <h3>One open at a time</h3>
      <div class="accordion mb-4" id="accordionExample">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Accordion Item #1
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea odio
              sunt cupiditate cumque asperiores exercitationem vel,
              reprehenderit quod soluta assumenda eum perferendis eos doloremque
              nulla delectus ullam aliquam illum dicta? Quaerat, adipisci
              obcaecati provident nesciunt delectus cumque rerum, doloribus
              fugit facere sunt minus omnis ipsa modi fuga quia repellat,
              voluptatibus dignissimos quasi ex consequatur magnam reprehenderit
              ut facilis alias. Ad earum cupiditate itaque rerum illo. At,
              voluptatem? Neque distinctio non nesciunt, nulla nobis
              voluptatibus accusamus placeat pariatur odio expedita maiores
              provident atque eum consectetur, maxime ad tenetur impedit
              tempora, officia perferendis reprehenderit. Molestias quo dolor
              repellat maiores nihil accusantium aperiam!
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              Accordion Item #2
            </button>
          </h2>
          <div
            id="collapseTwo"
            class="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque
              unde laborum harum suscipit deserunt itaque quaerat expedita.
              Repellat, modi! Sed itaque voluptate quasi corporis quaerat
              similique incidunt inventore, repudiandae eligendi iste, porro
              laborum deserunt vitae numquam reprehenderit natus quibusdam vel
              culpa molestiae animi odio! Nostrum molestias earum quidem quasi
              exercitationem molestiae sapiente quaerat, distinctio error
              eveniet fugiat iure vel praesentium aliquid. Quo odio sapiente
              voluptatum facilis perspiciatis at, enim quae culpa impedit
              molestias aspernatur cum architecto ipsum aliquid eos voluptate
              inventore eveniet quos alias dolore. Adipisci velit ex praesentium
              dolore.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingThree">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              Accordion Item #3
            </button>
          </h2>
          <div
            id="collapseThree"
            class="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi
              quos officiis quod, placeat rerum molestiae reprehenderit nihil
              dicta illum modi, nisi doloribus? Consequatur totam rem beatae
              eveniet inventore vel suscipit incidunt dolorem. A inventore
              blanditiis laborum perspiciatis mollitia facere ipsam voluptates
              reprehenderit laboriosam nihil id quasi omnis aspernatur vel
              eveniet, labore sunt eligendi sapiente assumenda unde. Atque est
              culpa expedita dolores distinctio libero alias sint
              necessitatibus, obcaecati, itaque nostrum cupiditate aliquid vel
              debitis? Doloribus repudiandae et at quibusdam cum ipsum veritatis
              nisi ad, tempora, accusamus dolor voluptatum tenetur rerum
              architecto. Aliquam quas nisi quis mollitia.
            </div>
          </div>
        </div>
      </div>
      <h3>Multiple open at a time</h3>
      <div class="accordion mt-4" id="accordionPanelsStayOpenExample">
        <div class="accordion-item">
          <h2 class="accordion-header" id="panelsStayOpen-headingOne">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseOne"
              aria-expanded="true"
              aria-controls="panelsStayOpen-collapseOne"
            >
              Accordion Item #1
            </button>
          </h2>
          <div
            id="panelsStayOpen-collapseOne"
            class="accordion-collapse collapse show"
            aria-labelledby="panelsStayOpen-headingOne"
          >
            <div class="accordion-body">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quam
              culpa dignissimos molestias alias numquam iusto quia, fugiat,
              quasi consectetur repellat dicta, illum voluptate eius itaque
              adipisci harum earum in est? Minima saepe alias rem exercitationem
              voluptates, veritatis aut ab eius assumenda autem nulla cum
              placeat cumque ipsa numquam fuga reprehenderit deserunt esse!
              Iusto excepturi vel ab quaerat, veritatis temporibus voluptatum
              sed. Nam, fuga iure optio quae incidunt ipsa nihil. Sit magni amet
              sed odio architecto!
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseTwo"
              aria-expanded="false"
              aria-controls="panelsStayOpen-collapseTwo"
            >
              Accordion Item #2
            </button>
          </h2>
          <div
            id="panelsStayOpen-collapseTwo"
            class="accordion-collapse collapse"
            aria-labelledby="panelsStayOpen-headingTwo"
          >
            <div class="accordion-body">
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cum
              neque sed voluptatem fuga numquam amet quidem ab atque temporibus
              minus perferendis itaque inventore labore debitis commodi id
              corrupti incidunt, deserunt magnam est. Voluptatem sed saepe qui
              tenetur harum, dolor reprehenderit consectetur, aliquid iste
              dignissimos maxime et. Odio, possimus libero! Vitae temporibus
              odit dignissimos quo doloribus magnam ducimus debitis, eius at
              aliquam dolore modi cupiditate aliquid ea voluptatibus fuga quam
              itaque amet. Est, quis quae. Non earum nesciunt, corrupti
              voluptate, amet cupiditate natus aspernatur doloribus nam est
              voluptatum. Eaque reprehenderit fugit dolores quibusdam iure
              voluptatum odio!
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="panelsStayOpen-headingThree">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseThree"
              aria-expanded="false"
              aria-controls="panelsStayOpen-collapseThree"
            >
              Accordion Item #3
            </button>
          </h2>
          <div
            id="panelsStayOpen-collapseThree"
            class="accordion-collapse collapse"
            aria-labelledby="panelsStayOpen-headingThree"
          >
            <div class="accordion-body">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsam,
              iste commodi numquam optio tempore necessitatibus eos odio
              dignissimos corrupti, dolor, fugit cum quisquam incidunt? Eligendi
              qui assumenda quod, eos aut, exercitationem odio nihil alias
              tenetur distinctio consequatur, sit ratione voluptatum atque!
              Voluptatibus eligendi eos similique, enim laboriosam culpa
              quisquam, vel modi quasi dolorem error quos libero voluptate
              veniam est labore architecto tempora id. Iste dolorum laudantium
              fugiat at est quis inventore recusandae soluta, sequi sapiente
              veritatis natus, laborum nostrum id eum. Dolorum corporis dolor
              porro hic explicabo illum, iste, magnam necessitatibus veritatis
              ut, obcaecati qui. Temporibus dolor a id tempore.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
