import React from "react";

import "./Breadcrumb.scss";
export default function Breadcrumb() {
  return (
    <section className="ui-breadcrumb">
      <h1>Breadcrumbs</h1>
      <h2>Use</h2>
      <p>
        Provide the user with their currentword location within a navigational
        heirarchy
      </p>
      <h2>Examples</h2>

      <nav className="bc1" aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="#">Home</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Library
          </li>
        </ol>
      </nav>

      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="#">Home</a>
          </li>
          <li class="breadcrumb-item">
            <a href="#">Recipes</a>
          </li>
          <li class="breadcrumb-item">
            <a href="#">Desserts</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Chocolate Chip Cookie
          </li>
        </ol>
      </nav>

      <nav className="bc3" aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="#">Home</a>
          </li>
          <li class="breadcrumb-item">
            <a href="#">Animals</a>
          </li>
          <li class="breadcrumb-item">
            <a href="#">Dogs</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Snoopy
          </li>
        </ol>
      </nav>
    </section>
  );
}
