import React, { useEffect } from "react";

import "./Links.scss";

export default function Links() {
  return (
    <section className="ui-links">
      <h1>Links</h1>
      <h2>Use</h2>
      <p>
        Navigate the user to another location (e.g. - other page, email address,
        phone number, file)
      </p>
      <h2>Examples</h2>
      <p>
        Links must be{" "}
        <a className="link-1" href="#">
          distiguished from surrounding text.
        </a>
      </p>
      <p>
        Links should have a hover state which activates when the{" "}
        <a href="" className="link-2">
          user's cursor hovers over the link
        </a>
        .
      </p>
      <p>
        Links are often styled like buttons:{" "}
        <a href="https://twitter.com" className="link-3">
          <i class="fab fa-twitter"></i>
        </a>
      </p>
    </section>
  );
}
