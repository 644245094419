import React from "react";

export default function Input() {
  return (
    <section className="ui-input">
      <h1>Inputs</h1>
      <h2>Use</h2>
      <p>Allows the user to enter information on a page</p>
      <h2>Examples</h2>
      <h3>Text Field</h3>
      <form className="mb-4" action="">
        <input type="text" />
      </form>
      <h3>Text Area</h3>
      <form className="mb-4" action="">
        <textarea name="" id="" cols="30" rows="10"></textarea>
      </form>
      <h3>Select</h3>
      <form action="" className="mb-4">
        <select class="form-select" aria-label="Default select example">
          <option selected>Open this select menu</option>
          <option value="1">One</option>
          <option value="2">Two</option>
          <option value="3">Three</option>
        </select>
      </form>
      <h3>Radio</h3>
      <form action="" className="mb-4">
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="flexRadioDefault"
            id="flexRadioDefault1"
          />
          <label class="form-check-label" for="flexRadioDefault1">
            Default radio
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="flexRadioDefault"
            id="flexRadioDefault1"
          />
          <label class="form-check-label" for="flexRadioDefault1">
            Default radio
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="flexRadioDefault"
            id="flexRadioDefault1"
          />
          <label class="form-check-label" for="flexRadioDefault1">
            Default radio
          </label>
        </div>
      </form>
      <h3>Checkbox</h3>
      <form action="" className="mb-4">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            id="flexCheckDefault"
          />
          <label class="form-check-label" for="flexCheckDefault">
            Default checkbox
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            id="flexCheckDefault"
          />
          <label class="form-check-label" for="flexCheckDefault">
            Default checkbox
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            id="flexCheckDefault"
          />
          <label class="form-check-label" for="flexCheckDefault">
            Default checkbox
          </label>
        </div>
      </form>

      <h3>Range</h3>
      <input
        type="range"
        class="form-range"
        min="0"
        max="5"
        step="0.5"
        id="customRange3"
      ></input>
    </section>
  );
}
