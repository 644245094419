import React from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../components/Sidebar/Sidebar";
import { Switch, Route, useRouteMatch } from "react-router-dom";

// containers
import Home from "../Home/Home";
import Navigation from "../Navigation/Navigation";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Input from "../Input/Input";
import Links from "../Links/Links";
import Button from "../Button/Button";
import Collapse from "../Collapse/Collapse";
import Accordion from "../Accordion/Accordion";
import Hamburger from "../Hamburger/Hamburger";
import Carousel from "../Carousel/Carousel";
import Pagination from "../Pagination/Pagination";
import Progress from "../Progress/Progress";
import Tooltips from "../Tooltips/Tooltips";
import Popovers from "../Popovers/Popovers";
import Modal from "../Modal/Modal";

export default function Content() {
  return (
    <>
      <main>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-4">
              <Sidebar />
            </div>
            <div className="col-12 col-lg-8">
              <Route exact path={"/"} component={Home} />
              <Route exact path={"/navigation"} component={Navigation} />
              <Route exact path={"/breadcrumb"} component={Breadcrumb} />
              <Route exact path={"/input"} component={Input} />
              <Route exact path={"/link"} component={Links} />
              <Route exact path={"/button"} component={Button} />
              <Route exact path={"/collapse"} component={Collapse} />
              <Route exact path={"/accordion"} component={Accordion} />
              <Route exact path={"/hamburger"} component={Hamburger} />
              <Route exact path={"/carousel"} component={Carousel} />
              <Route exact path={"/pagination"} component={Pagination} />
              <Route exact path={"/progress"} component={Progress} />
              <Route exact path={"/tooltip"} component={Tooltips} />
              <Route exact path={"/popover"} component={Popovers} />
              <Route exact path={"/modal"} component={Modal} />
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
