import React from "react";
import "./Carousel.scss";

export default function Carousel() {
  return (
    <section className="ui-carousel">
      <h1>Carousel</h1>
      <h2>Use</h2>
      <p>A slideshow component used to cycle through content</p>
      <h2>Examples</h2>
      {/* <h3>Slides Only</h3>
      <div
        id="carouselExampleSlidesOnly"
        class="carousel slide mb-4"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div className="ui-block ui-block--1">Slide 1</div>
          </div>
          <div class="carousel-item">
            <div className="ui-block ui-block--2">Slide 2</div>
          </div>
          <div class="carousel-item">
            <div className="ui-block ui-block--3">Slide 3</div>
          </div>
        </div>
      </div> */}
      <h3>With Controls</h3>
      <div
        id="carouselExampleControls"
        class="carousel slide mb-4"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div className="ui-block ui-block--1">Slide 1</div>
          </div>
          <div class="carousel-item">
            <div className="ui-block ui-block--2">Slide 2</div>
          </div>
          <div class="carousel-item">
            <div className="ui-block ui-block--3">Slide 3</div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
      <h3>With Controls and Indicators</h3>
      <div
        id="carouselExampleIndicators"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            class="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div className="ui-block ui-block--1">Slide 1</div>
          </div>
          <div class="carousel-item">
            <div className="ui-block ui-block--2">Slide 2</div>
          </div>
          <div class="carousel-item">
            <div className="ui-block ui-block--3">Slide 3</div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </section>
  );
}
