import React from "react";

export default function Welcome() {
  return (
    <section className="home">
      <h1>Home</h1>
      <p>Hello Banyan!</p>
    </section>
  );
}
