import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import "./Sidebar.scss";

export default function Sidebar() {
  const location = useLocation();

  const [currentPage, setCurrentPage] = useState("");

  useEffect(() => {
    setCurrentPage(location.pathname.substring(1));
  }, [location]);

  return (
    <>
      <nav className="sidebar">
        <p className="sidebar__section-heading">Basic Elements</p>
        <ul className="list-group">
          <li
            className={`list-group-item list-group-item-action ${
              currentPage === "navigation" ? "active" : null
            }`}
            aria-current={`${currentPage === "navigation" ? true : false}`}
          >
            <Link to="/navigation">Navbar</Link>
          </li>
          <li
            className={`list-group-item list-group-item-action ${
              currentPage === "breadcrumb" ? "active" : null
            }`}
            aria-current={`${currentPage === "breadcrumb" ? true : false}`}
          >
            <Link to="/breadcrumb">Breadcrumb</Link>
          </li>
          <li
            className={`list-group-item list-group-item-action ${
              currentPage === "input" ? "active" : null
            }`}
            aria-current={`${currentPage === "input" ? true : false}`}
          >
            <Link to="/input">Input Fields</Link>
          </li>
          <li
            className={`list-group-item list-group-item-action ${
              currentPage === "link" ? "active" : null
            }`}
            aria-current={`${currentPage === "link" ? true : false}`}
          >
            <Link to="/link">Links</Link>
          </li>
        </ul>
        <p className="sidebar__section-heading">Interactive Elements</p>
        <ul className="list-group">
          <li
            className={`list-group-item list-group-item-action ${
              currentPage === "button" ? "active" : null
            }`}
            aria-current={`${currentPage === "button" ? true : false}`}
          >
            <Link to="/button">Buttons</Link>
          </li>
          <li
            className={`list-group-item list-group-item-action ${
              currentPage === "collapse" ? "active" : null
            }`}
            aria-current={`${currentPage === "collapse" ? true : false}`}
          >
            <Link to="/collapse">Collapse</Link>
          </li>
          <li
            className={`list-group-item list-group-item-action ${
              currentPage === "accordion" ? "active" : null
            }`}
            aria-current={`${currentPage === "accordion" ? true : false}`}
          >
            <Link to="/accordion">Accordion</Link>
          </li>
          <li
            className={`list-group-item list-group-item-action ${
              currentPage === "hamburger" ? "active" : null
            }`}
            aria-current={`${currentPage === "hamburger" ? true : false}`}
          >
            <Link to="/hamburger">Hamburger</Link>
          </li>
          <li
            className={`list-group-item list-group-item-action ${
              currentPage === "carousel" ? "active" : null
            }`}
            aria-current={`${currentPage === "carousel" ? true : false}`}
          >
            <Link to="/carousel">Carousel</Link>
          </li>
          <li
            className={`list-group-item list-group-item-action ${
              currentPage === "pagination" ? "active" : null
            }`}
            aria-current={`${currentPage === "pagination" ? true : false}`}
          >
            <Link to="/pagination">Pagination</Link>
          </li>
          {/* <li
            className={`list-group-item list-group-item-action ${
              currentPage === "progress" ? "active" : null
            }`}
            aria-current={`${currentPage === "progress" ? true : false}`}
          >
            <Link to="/progress">Progress Bar</Link>
          </li> */}
          <li
            className={`list-group-item list-group-item-action ${
              currentPage === "tooltip" ? "active" : null
            }`}
            aria-current={`${currentPage === "tooltip" ? true : false}`}
          >
            <Link to="/tooltip">Tooltips</Link>
          </li>
          <li
            className={`list-group-item list-group-item-action ${
              currentPage === "popover" ? "active" : null
            }`}
            aria-current={`${currentPage === "popover" ? true : false}`}
          >
            <Link to="/popover">Popovers</Link>
          </li>
          <li
            className={`list-group-item list-group-item-action ${
              currentPage === "modal" ? "active" : null
            }`}
            aria-current={`${currentPage === "modal" ? true : false}`}
          >
            <Link to="/modal">Modal Window</Link>
          </li>
        </ul>
      </nav>
    </>
  );
}
