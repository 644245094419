import React, { useEffect } from "react";

import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min";

export default function Popovers() {
  useEffect(() => {
    var popoverTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="popover"]')
    );
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
      return new bootstrap.Popover(popoverTriggerEl);
    });
  }, []);

  return (
    <section className="ui-popover">
      <h1>Popovers</h1>
      <h2>Use</h2>
      <p>
        Small bits of additional related content that appears when a user clicks
        on an element.
      </p>
      <h2>Examples</h2>
      <button
        type="button"
        class="btn btn-primary me-4"
        data-bs-container="body"
        data-bs-toggle="popover"
        data-bs-placement="top"
        data-bs-content="Top popover"
      >
        Popover on top
      </button>
      <button
        type="button"
        class="btn btn-warning me-4"
        data-bs-container="body"
        data-bs-toggle="popover"
        data-bs-placement="right"
        data-bs-content="Right popover"
      >
        Popover on right
      </button>
      <button
        type="button"
        class="btn btn-danger me-4"
        data-bs-container="body"
        data-bs-toggle="popover"
        data-bs-placement="bottom"
        data-bs-content="Bottom popover"
      >
        Popover on bottom
      </button>
      <button
        type="button"
        class="btn btn-success"
        data-bs-container="body"
        data-bs-toggle="popover"
        data-bs-placement="left"
        data-bs-content="Left popover"
      >
        Popover on left
      </button>
    </section>
  );
}
