import React, { useState } from "react";

import "./Button.scss";

import { useWindowSize } from "react-use";
import Confetti from "react-confetti";

export default function Button() {
  const { width, height } = useWindowSize();
  const [confetti, setConfetti] = useState(false);
  const [power, setPower] = useState(false);

  return (
    <section className="ui-button">
      <h1>Buttons</h1>
      <h2>Use</h2>
      <p>Activated by the user to trigger an action</p>
      <h2>Examples</h2>
      <div className="ui-button__wrapper">
        <button
          className="ui-button__button ui-button__button--1"
          onClick={() => {
            setConfetti((currentState) => !currentState);
          }}
        >
          {confetti ? "Back to Work!" : "Party!"}
        </button>
        <button
          className={`ui-button__button ui-button__button--2 ${
            power ? "on" : null
          }`}
          onClick={() => {
            setPower((currentState) => !currentState);
          }}
        >
          <i class="fas fa-power-off"></i>
        </button>
        <div className="ui-button__button3-wrapper">
          {" "}
          <button className="ui-button__button ui-button__button--3">
            <span>Read More</span>
          </button>
        </div>
      </div>
      {confetti && <Confetti width={width} height={height} />}
    </section>
  );
}
