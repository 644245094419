import { useState, useEffect } from "react";

// 3rd party imports
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { Switch, Route } from "react-router-dom";

// local imports
import "./assets/css/all.css"; // font-awesome
import "./assets/sass/App.scss";
import Content from "./containers/Content/Content";
import Header from "./components/Header/Header";

function App() {
  return (
    <>
      <Header />
      <Content />
    </>
  );
}

export default App;
