import React, { useEffect } from "react";

import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min";

export default function Tooltips() {
  useEffect(() => {
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl);
    });
  }, []);
  return (
    <section className="ui-tooltips">
      <h1>Tooltips</h1>
      <h2>Use</h2>
      <p>
        Short text-based hints, tips, or clarifications that appear when the
        user interacts with an element
      </p>
      <h3>Examples</h3>
      <button
        type="button"
        class="btn btn-primary me-4"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Tooltip on top"
      >
        Tooltip on top
      </button>
      <button
        type="button"
        class="btn btn-warning me-4"
        data-bs-toggle="tooltip"
        data-bs-placement="right"
        title="Tooltip on right"
      >
        Tooltip on right
      </button>
      <button
        type="button"
        class="btn btn-danger me-4"
        data-bs-toggle="tooltip"
        data-bs-placement="bottom"
        title="Tooltip on bottom"
      >
        Tooltip on bottom
      </button>
      <button
        type="button"
        class="btn btn-success me-4"
        data-bs-toggle="tooltip"
        data-bs-placement="left"
        title="Tooltip on left"
      >
        Tooltip on left
      </button>
    </section>
  );
}
